label.bold{
    width: 40%;
}
dialog.employeeForm {
    width: 32%!important;
}
@media screen and (max-width: 768px) {
    dialog.employeeForm {
        width: 80%!important;
    }
    
}
.formInput input{
    width: 65%;
}