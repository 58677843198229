$brand-primary: #2196F3;
$bg-light-gray: #f5f5f5;

.progress {
	background-color: $bg-light-gray;
	border-radius: 3px;
	box-shadow: none;
	overflow: hidden;
	flex: 1;

	&.vertical {
		position: relative;
		width: 20px;
		height: 200px;
		display: inline-block;
		margin-right: 10px;

		>.progress-bar {
			width: 100% !important;
			position: absolute;
			bottom: 0;
		}
	}


	div[role=progressbar] {
		background-color: $brand-primary;
		box-shadow: none;
		height: 100%;

		&.text-left {
			text-align: left;

			span {
				margin-left: 10px;
			}
		}

		&.text-right {
			text-align: right;

			span {
				margin-right: 10px;
			}
		}


		animation: (progress-bar-stripes 2s linear infinite);

		$color: rgba(255, 255, 255, .15);
		$angle: 45deg;
		background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);

		background-size: 40px 40px;
		background-color: $brand-primary;
	}

}


@keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}

	to {
		background-position: 0 0;
	}
}