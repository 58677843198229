:root {
	--header-height: 3.5rem;
}
header.topHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;
	background-color: #86212F;
	height: var(--header-height);
	}

header.topHeader>* {
	background: inherit;
}
header.topHeader>.logo {
	font-family: 'Bevan';
	font-style: oblique;
	font-size: 1.8rem;
}
header.topHeader>.centerItem {
	flex: 1;
	text-align: center;
}
header.topHeader>.clock {
	font-size: 1.5rem;
	font-weight: 300;
}

header.topHeader>.currentUser {
	font-size: 1.5rem;
	padding: 0 0.625rem;
}
@media only screen and (max-width: 576px) {
	:root {
		--header-height: 2.5rem;
	}
	header.topHeader>.logo {
		font-size: 1.3rem;
	}
	header.topHeader>.clock ,header.topHeader>.currentUser {
		font-size: 1.2rem;
	}
}