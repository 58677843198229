:root {
	--primary-color-bg: #86212F;
	--primary-color-bg-disabled: #b04445;
	--primary-color-text: #ffffff;
	--secondary-color-bg: #ffc107;
	--secondary-color-disabled: #FFFFAA;
	--secondary-color-text: #000000;
	--tertiary-color-bg: #fff;
	--tertiary-color-disabled: #c9c9c9;
	--tertiary-color-text: #6e6e6e;
	--link-color-text: blue;
}
html{
	font-size: 16px;
}
@media only screen and (max-width: 768px) {
	html {
		font-size: 14px;
	}
}
@media only screen and (max-width: 576px) {
	html{
		font-size: 11px;
	}

}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100vh;
	display: flex;
	flex-direction: column;

	header {
		flex: 0 0 var(--header-height);
	}

	section.pageContent {
		flex: 1;
	}
}
a{
	color: var(--primary-color-bg);
	text-decoration: none;
}
a:hover{
	color: var(--secondary-color-bg);
	text-decoration: none;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.row{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0.5rem;
	.col6{
		width: 50%;
	}
	.col12{
		width: 100%;
		& stong{
			text-transform: capitalize;
		}
	}

}
.w25{
	width: 25%;
}
.pageContent {
	background: #f1f4f5;
	padding: 1.25rem 1.875rem;
	flex: 1;

	.page-header {
		margin: 0;
		padding-bottom: 1rem;
		padding-top: 1.25rem;
		border-bottom: 0;

		h2 {
			margin: 0;
			padding: 0;
			font-size: 1.25rem;
			font-weight: 500;
			color: #777;
		}

		.breadcrumb {
			margin: 0;
			padding: 0;
			background-color: transparent;
			list-style: none;
			color: #777;
			font-weight: 300;
		}
	}
}

.text-center {
	text-align: center!important;
}

.text-right {
	text-align: right!important;
}

.rounded-circle {
	border-radius: 100%;
}

.cursorPointer {
	cursor: pointer;
}


/*#region Card */
.card {
	display: flex;
	flex-direction: column;

	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;

	border: 1px solid lightgrey;
	box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04);

	border-radius: 0.5rem;
	overflow: clip;

	.head {
		font-size: 1.5em;
		font-weight: 500;
		background: rgba(0, 0, 0, 0.1);
		padding: 5px;
		border-bottom: lightgray 1px solid;
	}

	.body {
		flex: 1 1 auto;
		padding: 1.5rem 1.5rem;
	}

	ul.body {
		padding: 0.5rem;
		margin: 0;
		

		a {
			text-decoration: none;
		}

		li {
			width: 100%;
			height: 30px;
			line-height: 30px;
			max-width: 50ch; //TODO Esto puede no aprovechar (o incluso exceder) el tamaño de la carta
			position: relative;
			div,
			span {
				// position: absolute;
				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		&.customMarkers li {
			list-style-type: none;
			padding-left: 2.75rem;

			svg {
				position: absolute;
				translate: -125% 5px; //TODO No queda perfecto y me pone nervioso
			}


		}

	}
}

/*#endregion Card */

/*#region FullCalendar */

.fc-view-harness {
	background: white;
}

.fc-col-header-cell {
	background-color: var(--primary-color-bg);
	color: white;
}

.fc .fc-toolbar.fc-header-toolbar {
	margin-bottom: 0;
}

/*#endregion FullCalendar */

/*#region Buttons*/

button.btn {
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	touch-action: manipulation;
	cursor: pointer;
	padding: 6px 12px;
	user-select: none;
	border-radius: 3px;
	box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, 0.12);
	border: 0;
}
.btn-icon {
	padding: 0px!important;
	box-shadow: none!important;
	background: transparent!important;
	margin-right: 0.4rem;
}

button.btn.primary {
	background-color: var(--primary-color-bg);
	color: var(--primary-color-text);
}

button.btn.secondary {
	background-color: var(--secondary-color-bg);
	color: var(--secondary-color-text);
}

button.btn.tertiary {
	background-color: var(--tertiary-color-bg);
	color: var(--tertiary-color-text);
}
button.link {
	appearance: none;
	background: none;
	border: none;
	cursor: pointer;
	font: inherit;
	text-decoration: underline;
	color: var(--link-color-text);

}

/*#endregion Buttons*/

/*#region Inputs*/

select,
select:focus,
input,
input:focus {
	border-color: #e7ebec;
	box-shadow: none;
}

select,
input {
	max-height: 2.188rem;
	padding: 0.375rem 0.75rem;
	color: #555;
	background-color: #fff;
	border: 1px solid #0b0b0b;
	border-radius: 4px;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/*#endregion Inputs*/

/*#region Dialog*/
::backdrop {
	background-image: linear-gradient(45deg, #737373, #65363a, #414141);
	opacity: 0.75;
}

dialog {
	pointer-events: none;
	opacity: 0;
	display: flex;
	transition: opacity 0.5s;

	flex-direction: column;

	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;

	border: 1px solid lightgrey;
	box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07),
		0 10px 20px -2px rgba(0, 0, 0, 0.04);

	border-radius: 0.5rem;
	overflow: clip;

	padding: 0;

	.head {
		font-size: 1.5em;
		font-weight: 500;
		background: rgba(0, 0, 0, 0.05);
		padding: 15px;
		border-bottom: lightgray 1px solid;
		margin: 0;
	}

	.body {
		flex: 1 1 auto;
		padding: 1.5rem 1.5rem;
		overflow-x: auto;
	}

	.footer {
		font-size: 1.5em;
		font-weight: 500;
		padding: 15px;
		border-top: lightgray 1px solid;
		margin: 0;

		display: flex;
		justify-content: flex-end;
	}
}

dialog[open] {
	opacity: 1;
	pointer-events: inherit;
}

/*#endregion Dialog*/

/*#region Table*/
.commonTable {
	border-collapse: collapse;
	width: 100%;

	thead>tr>th {
		font-size: 1.20rem;
		text-align: left;
		padding: 0.2rem;
	}

	td {
		border: none 0px;
		height: 3.125rem;
		border-top: 1px solid grey;
		border-bottom: 1px solid grey;
		box-sizing: border-box;
		transition: box-shadow ease-in-out 0.2s;
		padding: 0.2rem;

	}

	thead>tr>th {
		background-color: var(--primary-color-bg);
		color: var(--primary-color-text);
	}
	input{
		width: 25px;
		height: 25px;
	}
	tbody {
		tr:nth-child(odd)>td {
			background-color: #eee;
		}

		tr:nth-child(even)>td {
			background-color: #fff;
		}

		tr:hover>td {
			border-width: 1px;
			box-shadow: 0px 1px 0px 0px grey, 0px -1px 0px 0px grey;
		}
	}

}
.table-header{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
label{
	font-weight: normal;
	&.bold{
		font-weight: bold;
		text-transform: capitalize;
		width: 25%;
		display: inline-block;

	}
}
.row-exit-active {
	opacity: 0;
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	overflow: hidden;
	transition: all 0.5s ease-out;
}

/*#endregion Table*/

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 3.75rem;
	height: 2.125rem;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 1.625rem;
	width: 1.625rem;
	left: 0.25rem;
	bottom: 0.25rem;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: var(--primary-color-bg);
}

input:focus+.slider {
	box-shadow: 0 0 1px var(--primary-color-bg);
}

input:checked+.slider:before {
	-webkit-transform: translateX(1.5rem);
	-ms-transform: translateX(1.5rem);
	transform: translateX(1.5rem);
}

/* Rounded sliders */
.slider.round {
	border-radius: 2.125rem;
}

.slider.round:before {
	border-radius: 50%;
}
.mb-2{
	margin-bottom: 2rem;
}
.topButtons{
	li{
		display: inline-block;
		margin-right: 1rem;
		&:last-child{
			margin-right: 0;
		}
	}
	
}
.bottomButtons {
	li {
		display: inline-block;
		margin-right: 1rem;

		&:last-child {
			margin-right: 0;
		}
		& .btn {
			font-size: 1.2rem;
			font-weight: 700;
		}
	}
}
.mx05{
	margin: 0.5rem 0;
}
.chip {
	display: inline-block;
	padding: 0.25rem 0.5rem;
	margin: 0.25rem;
	border-radius: 1rem;
	background-color: var(--secondary-color-disabled);
	color: var(--secondary-color-text);
	font-size: 0.85rem;
}
.btn-primary {
	white-space: nowrap;
	padding: 0.5rem 1rem;
	background-color: var(--primary-color-bg);
	color: var(--primary-color-text);
	border-radius: 0.25rem;
	transition: background-color 0.5s ease, color 0.7s ease;
	&:hover {
		background-color: var(--primary-color-bg-hover);
		color: #000;
	}

	&:disabled {
		background-color: var(--primary-color-bg-disabled);
		color: var(--primary-color-text);
	}
}
.hidden{
	display: none;
}
.justify-content-center{
	justify-content: center;
}
.mr1{
	margin-right:1rem ;
}
.mt1 {
	margin-top: 1rem;
}
.pt1{
	padding-top: 1rem;
}
.pagination{
	margin-top: 1rem;
}
.skeleton {
	background-color: #e2e5e7;
	// The shine that's going to move across the skeleton:
	background-image:
		linear-gradient(90deg,
			rgba(#fff, 0),
			rgba(#fff, 0.5),
			rgba(#fff, 0));
	background-size: 40px 100%; // width of the shine
	background-repeat: no-repeat; // No need to repeat the shine effect
	background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
	animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
	& td{
		height: 200px !important;
	}
}

@keyframes shine {
	to {
		// Move shine from left to right, with offset on the right based on the width of the shine - see background-size
		background-position: right -40px top 0;
	}
}
.pageNumber{
	width: 25px;
	height: 25px;
	background-color: none;
	display: inline-block;
	cursor: pointer;
	border-radius: 20px;
	border: 1px solid;
	text-align: center;
	margin: 0 5px;
	vertical-align: middle;
	border: var(--primary-color-bg) 1px solid;
	&:disabled{
		background-color: var(--primary-color-bg);
		color: var(--primary-color-text);
	}
}
.toggleButton {
	border-radius: 100%;
	border: 1px solid grey;
	aspect-ratio: 1;
	cursor: pointer;
	transition: background-color 0.5s ease, color 0.5s ease;
	&:hover {
		background: var(--primary-color-bg);

		&>svg path {
			fill: #ffffff !important;
		}
	}
}
.page {
	display: flex-end;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin-top: 1.5rem;
	flex-direction: column;
}
.modalsubline {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
a.fc-daygrid-day-number {
	color: #000!important;
}
.fc-scrollgrid-sync-inner a{
	color: #ffffff;
}
.fc {
	& .fc-bg-event{
		opacity: 1;
	}
	& .fc-event-title{
		font-weight: bold;
		text-align: left;
		display: block;
		& span{
			font-weight: 300;
			display: block;
		}

	}
	
}
.fc-daygrid-dot-event {
	background: #2eff4e;
	color: #000000!important;
}
.fc-daygrid-event-dot{
	display: none;
}
.fc-event.fc-event-end.fc-event-future.text-center.fc-bg-event {	
	display: flex;
	justify-content: end;
	align-items: end;
}
.holiday-event {
	background: #ff7043 !important;
}

.vacation-event {
	background: #4286f4 !important;
}

.schedule-event {
	background: #30ff4e !important;
}
.event-title {
	display: flex;
	justify-content: start;
	width: 100%;
	font-weight: bold;
	margin-top: 0.5rem;
	flex-direction: column;

	& span{
		display: block;
		font-size: 0.75rem;
		width: 100%;
		font-weight: 400;
	}
}
.fc .fc-daygrid-day-top {
	flex-direction: row!important;
}
.tooltip {
	position: absolute;
	z-index: 100;
	background: #333131;
	color: #fff;
	border-radius: 5px;
	padding: 4px;
}
.tooltip::after {
	content: '';
	position: absolute;
	left: 42%;
	top: 100%;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #333131;
	clear: both;
}
.overflowx{
	overflow-x: auto;
}
@media only screen and (max-width: 576px) {
	.pageContent {
		padding: 1rem 1.5rem;
	}
	.mb-xs-1{
		margin-bottom: 1rem;
	}
		.event-title{
			font-size: 0.8rem;
		}
		.fc-daygrid-day-events{
			margin-bottom: 0em;
		}
}