dialog {
    &[open] {
        width: 40%!important;
        max-height: 80%;
    }


}
.calendarView {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
   
    .schedule {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
    }
}
.scheduleClass{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.calendarSummary{
    display: "flex";
    align-items: "flex-start";
    justify-content: "space-between"
}
@media screen and (max-width: 768px) {
    dialog {
        &.editEmployee {
            width: 80% !important;
        }
    }
        .calendarView {
            flex-direction: column-reverse;
            .schedule{
                margin: 0.5rem;
                flex-direction: column;
                flex-wrap: wrap;
                margin-bottom: 0rem;
            }
        }
        .scheduleClass {
            flex-direction: row;
        }

}
