.calendarMap{
    margin: 0.65rem 0;
    cursor: pointer;
    font-size: 1.1rem;
    & input{
        width: 1rem;
        height: 1rem;
    }
}
dialog.assignTags {
    width: 50% !important;
}

@media screen and (max-width: 768px) {
    dialog.assignTags {
        width: 80% !important;
    }

}