:root {
	--table-border: 0.5px solid #dddddd;
}
.schedulesPageHeader{

}
.schedulesPageContainer {
	font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
	
	.calendar{
		overflow-x: auto;
		display: grid;
			grid-template-columns: max-content repeat(7, 1fr);
			background-color: #f3f3f3;
			gap: 0.5px;
			overflow-x: auto;
	}
	.headerTitle {
		grid-column: 1 / -1;
		display: flex;
		gap: 0.625rem;
		padding: 0.313rem;

		.title {
			font-size: 150%;
			line-height: 1.5rem;
			font-weight: 700;
		}

	}

	.headerNavigator {
		// Full Row
		grid-column: 1 / -1;
		display: flex;
		flex-direction: row;
		font-size: 0.5rem;
		outline: var(--table-border);
		gap: 0.313rem;

		button {
			background: none;
			border: none;
			border-radius: 0;
			transition: background 0.1s ease-in-out;
			display:flex;
			align-items: center;
			&:hover {
				background: #00000050;
				font-weight: bold;
			}

			span {
				margin-right: 1rem;
			}
		}

	}

	.headerDays {
		display: contents;

		.totalSemana {
			background-color: #f1f4f5;
			background-color: var(--primary-color-bg);
			outline: var(--table-border);
		}

		.dayHeader {
			background-color: var(--primary-color-bg);
			color: white;

			display: flex;
			justify-content: space-around;

			padding: 2px 4px;
			outline: var(--table-border);

			font-size: 1em;
			font-weight: bold;
			text-transform: capitalize;
		}
	}

	.memberRow {
		display: contents;

		.peopleColumnCell {
			// background-color: white;
			display: flex;
			outline: var(--table-border);
			gap: 0.625rem;
			padding: 0.625rem;

			.employeeIcon {
				img {
					border-radius: 100%;
					background: #c8c6c4;
					width: 2.5rem;
				}
			}

			.employeeData {
				.name {
					font-weight: 500;
					max-width: 9.375rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.dayCell {
			background-color: white;
			padding: 3px 2px 1px 2px;
			outline: var(--table-border);
			display: flex;
			min-height: 3.125rem;
			min-width: 8.250rem;
			.event {
				border-left: 5px solid;
				background-color: rgba(37, 93, 9, 0.2);
				border-color: rgba(37, 93, 9, 0.5);
				color: #82353d;
				font-weight: 500;
				padding: 0.313rem 0.625rem;
				border-radius: 3px;
				flex: 1;
				font-size: 0.75rem;
			}
			.deletedEvent{
				text-decoration: line-through;
			}
			.holiday{
				background-color: rgba(255, 0, 0, 0.2);
				border-color: rgba(255, 0, 0, 0.5);
			}
			.vacation {
				background-color: rgba(0, 4, 255, 0.2);
				border-color: rgba(0, 4, 255, 0.5);
			}
			.eventIcons{
				display: flex;
				gap: 3px;
				justify-content: flex-end;
				align-items: center;
				color: #000000;
				min-height: 1.25rem;
			}
		}
	}


}
.skeletonWeekView {
	display: grid;
		width: 100%;
		grid-column: 1 / -1;
		height: 12.5rem;
		justify-content: center;
		align-items: center;
}