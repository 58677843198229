.subSection
{
	& .card{
		margin-bottom: 1rem;
	}
	
}
.Profile {
	display: flex;
	flex-wrap: wrap;

	.subSection {
		display: flex;
		flex-direction: column;
		padding: 1.25rem;
		gap: 1.25rem;
	}
}
.loading{
	min-height: 8.75rem;
	justify-content: center;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 1.3rem;
	
}
.height250 {
	min-height: 16.875rem; // Overrides the min-height for elements with both 'loading' and 'height25' classes
}
.toggleButton {
	border-radius: 100%;
	border: 1px solid grey;
	aspect-ratio: 1;
	cursor: pointer;
}
dialog{
	&[open]{
		width: 80%;
		max-height: 80%;
	}
	
	
}
.photo img:hover {
	border: 1px solid;
}
dialog {
	&.body{
			overflow-x: auto;
	}
	&.editEmployee {
		width: 30% !important;
		& input {
				width: 65%;
			}
	}
	
}

@media screen and (max-width: 768px) {
	dialog{
		&.editEmployee {
				width: 90% !important;
		}
	}
		
		.Profile {
			.subSection {
				
				padding: 0px;
				gap: 0px;
			}
		}

}

