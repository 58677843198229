.navigation {
	height: 100%;
	aspect-ratio: 1 / 1;
}

.navigation>.navigationTrigger {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: inherit;
	transition: filter ease-in-out 200ms;
	cursor: pointer;
}

.navigation>.navigationTrigger:hover {
	filter: brightness(1.35);
}

nav {
	position: fixed;
	top: var(--header-height);
	left: 0;
	overflow: hidden;
	background: #273238;
	bottom: 0;
	z-index: 100;
	transition: width 300ms ease 0ms, left 300ms;
	width: 4.688rem;
	left: -4.688rem;

	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

nav.showPreview {
	left: 0;
}

nav.showClick,
nav.show {
	left: 0;
	width: 16.875rem;
}

.navigation>.dismissClicker {
	opacity: 0;
	transition: opacity cubic-bezier(0.6, 0, 0.6, 1) 200ms;
}

.navigation>.dismissClicker.activeClick,
.navigation>.dismissClicker.activeHover {
	z-index: 5;
	position: absolute;
	top: var(--header-height);
	bottom: 0;
	left: 0;
	right: 0;
	background: #000000;
}


.navigation>.dismissClicker.activeClick {
	opacity: 20%;
}

.navigation>.dismissClicker.activeHover {
	opacity: 0%;
}


nav>ul {
	padding: 0;
	margin: 0;
	width: 16.875rem;
	overflow-y: auto;
}

nav>ul>a {
	height: 4.125rem;
	width: 16.875rem;
	color: #76828e;
	text-decoration: none;
	display: flex;
	align-items: center;
}

nav>ul>a:hover {
	background: #b04445;
	color: #fff;
}

nav>ul>a>li {
	list-style-type: none;
	width: 100%;
	display: flex;
	align-items: center;
}

nav>ul>a>li>svg {
	margin: 0 1.563rem;
	width: 1.563rem;
}