.calendarMap {
    margin: 0.65rem 0;
    cursor: pointer;
    font-size: 1.1rem;

    & input {
        width: 1rem;
        height: 1rem;
    }
}
.modalsubline{
    &p{
        margin: 0.5rem 0;
    }
   
}