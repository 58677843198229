h3 {
	margin-bottom: 5px;
}
.yearlytotal {
	display: flex;
	flex-direction: column;
	/* width: 13rem; */
	flex-wrap: nowrap;
}

.yearlytotal span {
	width: 12rem;
}